<template>
    <Header />

    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 m-20">
        <li v-for="illustration in illustrations" :key="illustration"
            class="col-span-1 divide-y divide-gray-200">
            <img :src="illustration" class="mx-auto" />
        </li>
    </ul>

</template>

<script>
    export default {
        name: 'Illustrations'
    }
</script>

<script setup>
import Header from "../components/Header.vue";

const illustrations = [
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter1/two.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter1/three.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter1/alexander-the-great.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/four.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/five.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/six.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter3/nine.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter3/ten.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter4/eleven.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter4/tweleve.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter4/thirteen.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter4/14&15.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/yara-illstrations/4.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/yara-illstrations/5.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/yara-illstrations/6.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/yara-illstrations/7.png',
    'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/yara-illstrations/8.png',

]

</script>