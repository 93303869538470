<template>

    <!-- Background for large screens and smaller -->
    <div class="fixed h-[76px] bg-secondary-500 top-0 left-0 w-full xl:hidden z-30"></div>
    <!-- Back Button -->
    <BackButton href="/part1" />
    <!-- Next Button -->
    <NextButton href="/part3" />

    <div class="mt-40 w-3/4 mx-auto">
        <div id="image-map-pro-container"></div>
    </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import NextButton from "@/components/NextButton.vue";

export default {
    components: {
    BackButton,
    NextButton
},
    mounted() {
        $('#image-map-pro-container').imageMapPro({
            "id": 5030,
            "editor": { "shapeCounter": { "spots": 7 } },
            "general": {
                "name": "Mediterranean map",
                "shortcode": "mediterraneanmap",
                "width": '100%',
                "height": '100%',
                "naturalWidth": '100%',
                "naturalHeight": '100%',
            },
            "image": { "url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter3/eight.png" },
            "shapes": {
                "pageload_animation": "fall-down",
                "glowing_shapes": 1,
                "glow_opacity": 1,
                "stop_glowing_on_mouseover": 0
            },
            "tooltips": { "show_tooltips": "click", "tooltip_animation": "grow" },
            "zooming": { "enable_navigator": 0 },
            "spots": [{
                "id": "spot-380",
                "title": "1",
                "x": 22.331,
                "y": 33.534,
                "x_image_background": 22.331,
                "y_image_background": 33.534,
                "default_style": {
                    "icon_type": "custom",
                    "icon_fontawesome_id": "map-pin",
                    "icon_fill": "#ff0000",
                    "icon_url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/image-svgrepo-com.svg",
                    "icon_shadow": 1
                },
                "mouseover_style": { "icon_fill": "#a1c814" },
                "tooltip_style": {
                    "background_opacity": 0.4947916666666667,
                    "position": "bottom",
                    "offset_x": -3.5721881674919977,
                    "offset_y": -40.04975693606754
                },
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-183581",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Image", "iconClass": "fa fa-camera" },
                                    "options": {
                                        "image": { "url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/four.png" },
                                        "general": { "classes": "w-full lg:w-96" }
                                    }
                                }]
                            }
                        }, {
                            "id": "sq-container-356491",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Button", "iconClass": "fa fa-link" },
                                    "options": {
                                        "button": {
                                            "text": "View Story",
                                            "link_to": "/nadia-story",
                                            "display": "block",
                                            "bg_color": "#003969"
                                        }
                                    }
                                }]
                            }
                        }]
                    }
                }
            }, {
                "id": "spot-1223",
                "title": "2",
                "x": 46.952,
                "y": 23.477,
                "x_image_background": 46.952,
                "y_image_background": 23.477,
                "width_image_background": 60,
                "height_image_background": 60,
                "default_style": {
                    "icon_type": "custom",
                    "icon_fontawesome_id": "map-pin",
                    "icon_fill": "#ff0000",
                    "icon_url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/image-svgrepo-com.svg",
                    "icon_shadow": 1
                },
                "mouseover_style": { "icon_fill": "#a1c814" },
                "tooltip_style": { "background_opacity": 0.4947916666666667, "position": "bottom" },
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-446241",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Image", "iconClass": "fa fa-camera" },
                                    "options": {
                                        "image": { "url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/five.png" },
                                        "general": { "classes": "w-full lg:w-96" }
                                    }
                                }]
                            }
                        }, {
                            "id": "sq-container-39881",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Button", "iconClass": "fa fa-link" },
                                    "options": {
                                        "button": {
                                            "text": "View Story",
                                            "link_to": "/nadia-story",
                                            "display": "block",
                                            "bg_color": "#003969"
                                        }
                                    }
                                }]
                            }
                        }]
                    }
                }
            }, {
                "id": "spot-2404",
                "title": "3",
                "x": 72.886,
                "y": 13.667,
                "x_image_background": 72.886,
                "y_image_background": 13.667,
                "default_style": {
                    "icon_type": "custom",
                    "icon_fontawesome_id": "map-pin",
                    "icon_fill": "#ff0000",
                    "icon_url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/image-svgrepo-com.svg",
                    "icon_shadow": 1
                },
                "mouseover_style": { "icon_fill": "#a1c814" },
                "tooltip_style": {
                    "background_color": "#ffffff",
                    "background_opacity": 0.7580729166666667,
                    "position": "bottom"
                },
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-862411",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Image", "iconClass": "fa fa-camera" },
                                    "options": {
                                        "image": { "url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/six.png" },
                                        "general": { "classes": "w-full lg:w-96" }
                                    }
                                }]
                            }
                        }, {
                            "id": "sq-container-631241",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Button", "iconClass": "fa fa-link" },
                                    "options": {
                                        "button": {
                                            "text": "View Story",
                                            "link_to": "/nadia-story",
                                            "display": "block",
                                            "bg_color": "#003969"
                                        }
                                    }
                                }]
                            }
                        }]
                    }
                }
            }, {
                "id": "spot-336",
                "title": "THE MAZE",
                "x": 85.814,
                "y": 36.188,
                "x_image_background": 85.814,
                "y_image_background": 36.188,
                "default_style": {
                    "icon_type": "custom",
                    "icon_fontawesome_id": "map-pin",
                    "icon_url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/maze-svgrepo-com.svg",
                    "icon_shadow": 1
                },
                "mouseover_style": { "icon_fill": "#a1c814" },
                "tooltip_style": {
                    "background_color": "#ffffff",
                    "background_opacity": 0.6705729166666666,
                    "position": "bottom"
                },
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-731",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Image", "iconClass": "fa fa-camera" },
                                    "options": {
                                        "heading": { "text": "My Shape" },
                                        "image": { "url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/seven.png" },
                                        "general": { "classes": "w-full lg:w-96" }
                                    }
                                }]
                            }
                        }]
                    }
                }
            }, {
                "id": "spot-5764",
                "title": "Nadia's Message",
                "x": 67.302,
                "y": 38.514,
                "x_image_background": 67.302,
                "y_image_background": 38.514,
                "default_style": {
                    "icon_type": "custom",
                    "icon_fontawesome_id": "map-pin",
                    "icon_url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/scroll-svgrepo-com.svg",
                    "icon_shadow": 1
                },
                "mouseover_style": { "icon_fill": "#a1c814" },
                "tooltip_style": { "background_opacity": 0.6705729166666666, "position": "bottom" },
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-762261",
                            "settings": {
                                "elements": [{
                                    "settings": { "name": "Button", "iconClass": "fa fa-link" },
                                    "options": {
                                        "button": {
                                            "text": "View message",
                                            "link_to": "/nadia-message",
                                            "display": "block",
                                            "bg_color": "#003969"
                                        }
                                    }
                                }]
                            }
                        }]
                    }
                }
            }]
        });
    }
};
</script>

<style>
</style>
