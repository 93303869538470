<template>

    <!-- Background for large screens and smaller -->
    <div class="fixed h-[76px] bg-secondary-500 top-0 left-0 w-full xl:hidden z-30"></div>
    <!-- Back Button -->
    <BackButton href="/part2" />
    <!-- Home Button -->
    <!-- <HomeButton /> -->

    <div class="max-w-5xl xl:grid grid-cols-2 mx-auto mt-40 space-y-6">
        <div class="">
            <transition
                appear
                enter-active-class="duration-1000 ease-out"
                enter-from-class="transform opacity-0 scale-0"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-75"
            >
                <img class="mx-auto xl:mx-0"
                     src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter3/nine.png"/>
            </transition>
        </div>
        <div id="one" class="">
            <transition
                enter-active-class="duration-1000 ease-out"
                enter-from-class="transform opacity-0 scale-0"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-75"
            >
                <img
                    v-show="one"
                    class="mx-auto xl:mx-0"
                    src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter3/ten.png"/>
            </transition>
        </div>
    </div>

</template>

<script>
import BackButton from "@/components/BackButton.vue";
import HomeButton from "@/components/HomeButton.vue";

export default {
    components: {
    BackButton,
    HomeButton
},
    data() {
        return {
            one: false,
        };
    },
    created() {
        setTimeout(() => {
            this.one = true;
            document.getElementById('one').scrollIntoView({behavior: "smooth", block: "start"});
        }, 2000);
    },
    mounted() {
        $('#image-map-pro-container4').imageMapPro({
            "id": 8191,
            "editor": {"tool": "poly", "shapeCounter": {"spots": 1, "polys": 5}},
            "general": {
                "name": "Chapter 3",
                "shortcode": "Part3",
                "width": "100%",
                "height": "100%",
                "naturalWidth": "100%",
                "naturalHeight": "100%",
                "center_image_map": 1
            },
            "image": {"url": "https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/lq/layer4.png"},
            "spots": [{
                "id": "poly-6389",
                "title": "Poly 1",
                "type": "poly",
                "x": 2.879,
                "y": 9.975,
                "width": 61.407,
                "height": 28.858,
                "x_image_background": 2.879,
                "y_image_background": 9.975,
                "width_image_background": 61.407,
                "height_image_background": 28.858,
                "default_style": {"background_color": "#ffffff"},
                "mouseover_style": {"background_color": "#000000"},
                "tooltip": {"enable_tooltip": 0},
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-403761",
                            "settings": {
                                "elements": [{
                                    "settings": {"name": "Heading", "iconClass": "fa fa-header"},
                                    "options": {"heading": {"text": "Poly 1"}}
                                }]
                            }
                        }]
                    }
                },
                "points": [{"x": 0.2897936401624902, "y": 0}, {"x": 0, "y": 99.13043655954267}, {
                    "x": 99.27788273806924,
                    "y": 100
                }, {"x": 100, "y": 1.5242838416808455}]
            }, {
                "id": "poly-5768",
                "title": "Poly 2",
                "type": "poly",
                "x": 5.193,
                "y": 44.538,
                "width": 56.526,
                "height": 26.035,
                "x_image_background": 5.193,
                "y_image_background": 44.538,
                "width_image_background": 56.526,
                "height_image_background": 26.035,
                "default_style": {"background_color": "#ffffff"},
                "mouseover_style": {"background_color": "#000000"},
                "tooltip_style": {"background_opacity": 0.7036458333333333, "position": "left"},
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-684281",
                            "settings": {
                                "elements": [{
                                    "settings": {
                                        "name": "Paragraph",
                                        "iconClass": "fa fa-paragraph"
                                    },
                                    "options": {"text": {"text": "The location of ancient cisterns and fountains in Alexandria: \na- Main water supply channel until the beginning of the 16th century, transformed into an \nunderground aqueduct in the 17th or 18th century\nb- Main water supply channel in the middle of the 17th century, transformed into an \nunderground aqueduct in the 17th or 18th century\nc- Underground aqueduct from the end of the 17th century\nd- Extension of the canal built between 1670 and 1687 \ne- 17th century inner harbour\nf- Aerial aqueduct from the 17th or 18th century  \n+ Wells and fountains from the Goad Maps (Charles Edward Goad (1848-1910) was a \nnoted cartographer and civil engineer)\n+ Kamīl cisterns \n+ Kamīl cisterns\n- - - - - - - - - - Alignment of Byzantine cisterns"}}
                                }]
                            }
                        }]
                    }
                },
                "points": [{"x": 1.4128728414442697, "y": 0}, {"x": 0, "y": 96.3845049150397}, {
                    "x": 98.27323785007124,
                    "y": 100
                }, {"x": 100, "y": 3.6144782117566576}]
            }, {
                "id": "poly-3610",
                "title": "Poly 3",
                "type": "poly",
                "x": 5.644,
                "y": 71.456,
                "width": 42.239,
                "height": 24.906,
                "x_image_background": 5.644,
                "y_image_background": 71.456,
                "width_image_background": 42.239,
                "height_image_background": 24.906,
                "default_style": {"background_color": "#ffffff"},
                "mouseover_style": {"background_color": "#000000"},
                "tooltip": {"enable_tooltip": 0},
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-403761",
                            "settings": {
                                "elements": [{
                                    "settings": {"name": "Heading", "iconClass": "fa fa-header"},
                                    "options": {"heading": {"text": "Poly 3"}}
                                }]
                            }
                        }]
                    }
                },
                "points": [{"x": 0, "y": 24.684017120966992}, {"x": 84.66256388330937, "y": 0}, {
                    "x": 100,
                    "y": 75.56385634754417
                }, {"x": 18.487094471085502, "y": 100}, {
                    "x": 13.65448771592014,
                    "y": 84.63373411659929
                }, {"x": 14.49483333425669, "y": 79.59596266950906}, {
                    "x": 11.344665847543336,
                    "y": 69.77360809271632
                }, {"x": 12.183882883831192, "y": 65.99397976236526}, {"x": 6.301463555475386, "y": 48.865556842258364}]
            }, {
                "id": "poly-5198",
                "title": "Poly 4",
                "type": "poly",
                "x": 61.003,
                "y": 68.758,
                "width": 37.181,
                "height": 13.676,
                "x_image_background": 61.003,
                "y_image_background": 68.758,
                "width_image_background": 37.181,
                "height_image_background": 13.676,
                "default_style": {"background_color": "#ffffff"},
                "mouseover_style": {"background_color": "#000000"},
                "tooltip_style": {"background_opacity": 0.65390625, "position": "right"},
                "tooltip_content": {
                    "squares_settings": {
                        "containers": [{
                            "id": "sq-container-194731",
                            "settings": {
                                "elements": [{
                                    "settings": {
                                        "name": "Paragraph",
                                        "iconClass": "fa fa-paragraph"
                                    },
                                    "options": {"text": {"text": "Different uses of underground cisterns \nBy the end of the 19th century, most underground cisterns were owned by al-Awqāf \n(Ministry of Religious Endowments) and were no longer in use. In 1940, the British Army \ndecided to use Ibn Battūta, Nabahna, Safwān, Zeriba and other underground cisterns as \nair-raid shelters to protect civilians from German and Italian air attacks."}}
                                }]
                            }
                        }]
                    }
                },
                "points": [{"x": 0.9540865873494326, "y": 0}, {
                    "x": 98.56787720327007,
                    "y": 2.75425892186187
                }, {"x": 100, "y": 100}, {"x": 0, "y": 95.41129019458286}]
            }]
        })
    }
};
</script>

<style>

</style>
