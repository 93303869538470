<template>
    <!-- Background for large screens and smaller -->
    <div class="fixed h-[76px] bg-secondary-500 top-0 left-0 w-full xl:hidden z-30"></div>
    <!-- Back Button -->
    <BackButton href="/"/>

    <div class="max-w-5xl mx-auto py-40">
        <div class="space-y-12">
            <div v-for="video in videos" :key="video.video_url">
                <h2 class="text-3xl font-extrabold text-center mb-4">{{ video.name }}</h2>
                <video class="w-full" controls>
                    <source :src="video.video_url" type="video/mp4">
                    <source :src="video.video_url" type="video/webm">
                    <source :src="video.video_url" type="video/ogv">
                </video>
            </div>
        </div>
    </div>
</template>

<script setup>
import BackButton from "@/components/BackButton.vue";

const videos = [
    {
        name: 'Ziad Morsy Part 1',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/ZIAD_MORSY_PART_1_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'Ziad Morsy Part 2',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/ZIAD_MORSY_PART_2_MAIN_NO_SUBS.mp4'
    },
]
</script>