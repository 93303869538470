<template>
    <!-- Background for large screens and smaller -->
    <div class="fixed h-[76px] bg-secondary-500 top-0 left-0 w-full xl:hidden z-30"></div>
    <!-- Back Button -->
    <BackButton href="/part2" />

    <div class="max-w-5xl mx-auto mt-40">
        <div class="space-y-12">
            <div class="">
                <transition
                    appear
                    enter-active-class="duration-1000 ease-out"
                    enter-from-class="transform opacity-0 scale-0"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="duration-200 ease-in"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-75"
                >
                    <img
                        class="mx-auto"
                        src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/four.png"
                    />
                </transition>
            </div>
            <div id="one" class="">
                <transition
                    enter-active-class="duration-1000 ease-out"
                    enter-from-class="transform opacity-0 scale-0"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="duration-200 ease-in"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-75"
                >
                    <img
                        v-show="one"
                        class="mx-auto"
                        src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/five.png"
                    />
                </transition>
            </div>
            <div id="two" class="">
                <transition
                    enter-active-class="duration-1000 ease-out"
                    enter-from-class="transform opacity-0 scale-0"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="duration-200 ease-in"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-75"
                >
                    <img
                        v-show="two"
                        class="mx-auto"
                        src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/chapter2/six.png"
                    />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import {ChevronLeftIcon} from "@heroicons/vue/24/solid"

export default {
    components: {
    ChevronLeftIcon,
    BackButton
},
    data() {
        return {
            one: false,
            two: false,
        };
    },
    created() {
        setTimeout(() => {
            this.one = true;
            document.getElementById('one').scrollIntoView({behavior: "smooth", block: "start"});
        }, 2000);
        setTimeout(() => {
            this.two = true;
            document.getElementById('two').scrollIntoView({behavior: "smooth", block: "start"});
        }, 4000);
    }
}
</script>

<style>

</style>
