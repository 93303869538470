<template>
    <Header />

    <div>Credits page</div>
</template>

<script>
import Header from "../components/Header.vue";

export default {
    components: {
        Header,
    },
}
</script>

<style>
</style>