import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Illustrations from '../views/Illustrations.vue'
import Videos from '../views/Videos.vue'
import ContactUs from '../views/ContactUs.vue'
import Credits from '../views/Credits.vue'
import Part1 from '../views/Part1.vue'
import Part2 from '../views/Part2.vue'
import Part3 from '../views/Part3.vue'
import Church from '../views/Church.vue'
import ClimateChange from '../views/ClimateChange.vue'
import DisappearanceOfTheTanks from '../views/DisappearanceOfTheTanks.vue'
import MapExtension from '../views/MapExtension.vue'
import NadiaMessage from '../views/NadiaMessage.vue'
import NadiaMessageEn from '../views/NadiaMessageEn.vue'
import NadiaStory from '../views/NadiaStory.vue'
import WaterCarrier from '../views/WaterCarrier.vue'
import WaterCompany from '../views/WaterCompany.vue'
import ZiadVideos from '../views/ZiadVideos.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/illustrations',
    name: 'Illustrations',
    component: Illustrations
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/contact-us',
    component: ContactUs
  },
  {
    path: '/credits',
    component: Credits
  },
  {
    path: '/part1',
    name: 'Part 1',
    component: Part1
  },
  {
    path: '/part2',
    component: Part2
  },
  {
    path: '/part3',
    component: Part3
  },
  {
    path: '/church',
    component: Church
  },
  {
    path: '/climate-change',
    component: ClimateChange
  },
  {
    path: '/disappearance-of-the-tanks',
    component: DisappearanceOfTheTanks
  },
  {
    path: '/map-extension',
    component: MapExtension
  },
  {
    path: '/nadia-message',
    component: NadiaMessage
  },
  {
    path: '/nadia-message-en',
    component: NadiaMessageEn
  },
  {
    path: '/nadia-story',
    component: NadiaStory
  },
  {
    path: '/water-carrier',
    component: WaterCarrier
  },
  {
    path: '/water-company',
    component: WaterCompany
  },
  {
    path: '/ziad-videos',
    component: ZiadVideos
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
