<template>
    <Header />

    <div class="max-w-5xl mx-auto py-40">
        <div class="space-y-12">
            <div v-for="video in videos" :key="video.video_url">
                <h2 class="text-3xl font-extrabold text-center">{{ video.name }}</h2>
                <video class="w-full" controls>
                    <source :src="video.video_url" type="video/mp4">
                    <source :src="video.video_url" type="video/webm">
                    <source :src="video.video_url" type="video/ogv">
                </video>
            </div>
        </div>
    </div>
</template>

<script setup>
import Header from "../components/Header.vue";

const videos = [
    {
        name: 'DR/EMAD KHALIL',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/DR_EMAD_KHALIL_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'DR/SHAHIR MAGED',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/DR_SHAHIR_MAGED_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'KATHRIN MATCHNIK',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/KATHRIN_MATCHNIK_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'MAI KORAIEM',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/MAI_KORAIEM_MAIN_SUBS.mp4'
    },
    {
        name: 'MANAR RAMADAN',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/MANAR_RAMADAN_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'NASER ZIDAN',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/NASER_ZIDAN_MAIN_SUBS.mp4'
    },
    {
        name: 'STAVRULA_SPANOUDI',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/STAVRULA_SPANOUDI_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'Ziad Morsy Part 1',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/ZIAD_MORSY_PART_1_MAIN_NO_SUBS.mp4'
    },
    {
        name: 'Ziad Morsy Part 2',
        video_url: 'https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/videos/ZIAD_MORSY_PART_2_MAIN_NO_SUBS.mp4'
    },
]
</script>