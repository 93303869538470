<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-100">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-500" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-secondary-500" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 flex items-center px-4">
                <a href="/">
                  <img class="h-10 w-auto" src="https://s3.eu-west-3.amazonaws.com/alex-goethe.asciisd.com/icons/goethe-black.png" alt="alex-goethe" />
                </a>
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2 space-y-1">
                  <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[item.current ? 'bg-primary-500 !text-gray-100' : 'text-gray-700 hover:bg-primary-700 hover:text-gray-100', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                    <component :is="item.icon" :class="[item.current ? 'text-gray-100' : 'text-gray-700 group-hover:text-gray-100', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}
                  </router-link>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="flex flex-1">
      <div class="fixed top-0 z-10 flex-shrink-0 flex h-16 bg-black shadow">
        <button type="button" class="px-4 bg-secondary-500 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CreditCardIcon ,
  PhoneIcon,
  HomeIcon,
  Bars3BottomLeftIcon,
  XMarkIcon,
  PlayIcon,
  PhotoIcon ,
} from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router'

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon, current: useRouter().currentRoute.value.name === 'Home' },
  { name: 'Illustrations', href: 'illustrations', icon: PhotoIcon, current: useRouter().currentRoute.value.name === 'Illustrations' },
  { name: 'Videos', href: 'videos', icon: PlayIcon, current: useRouter().currentRoute.value.name === 'Videos' },
  // { name: 'Contact Us', href: 'contact-us', icon: PhoneIcon, current: false },
  // { name: 'Credits', href: 'credits', icon: CreditCardIcon, current: false },
]
console.log(useRouter().currentRoute.value)

const sidebarOpen = ref(false)
</script>